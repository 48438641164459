module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"C:\\Users\\hrist\\Documents\\Website\\portfolio_v2\\my-portfolio-starter\\node_modules\\@christiandavid\\gatsby-theme-byfolio/src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Christos Constantinou","short_name":"CC","description":"This App contains information about my work experience as a creative technologist.","lang":"en","start_url":"/","background_color":"#000","theme_color":"#fff","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"94b2fd30bc69a879fea480afed33a0a8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"C:\\Users\\hrist\\Documents\\Website\\portfolio_v2\\my-portfolio-starter\\node_modules\\@christiandavid\\gatsby-theme-byfolio\\src\\layout\\index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-151335375-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"send_page_view":true,"cookie_name":"christos.tech"},"pluginConfig":{"head":false,"respectDNT":false}},
    }]
